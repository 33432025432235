var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-helicopter","no-header":"","visible":!!_vm.value},on:{"hidden":function($event){return _vm.$emit('input', null)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t(("service.helicopter." + _vm.value + "_an_helicopter"))))]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"helicopterFormRef",staticClass:"p-5",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('service.helicopter.model_registration'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('service.helicopter.model_registration'),"label-for":"organizationHelicopter"}},[_c('v-select',{attrs:{"input-id":"organizationHelicopter","placeholder":_vm.$t('service.helicopter.model_registration'),"label":"id","options":_vm.helicoOptions,"disabled":_vm.value === 'edit'},on:{"~open":function($event){return _vm.fetchOrganizationHelicopters()}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var modelType = ref.modelType;
var registration = ref.registration;
return [_vm._v(" "+_vm._s(modelType)+" - "+_vm._s(registration)+" ")]}},{key:"option",fn:function(ref){
var modelType = ref.modelType;
var registration = ref.registration;
return [_vm._v(" "+_vm._s(modelType)+" - "+_vm._s(registration)+" ")]}}],null,true),model:{value:(_vm.selectedHelicopter.organizationHelicopter),callback:function ($$v) {_vm.$set(_vm.selectedHelicopter, "organizationHelicopter", $$v)},expression:"selectedHelicopter.organizationHelicopter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-checkbox',{attrs:{"name":"helicopter-active","switch":"","nline":""},model:{value:(_vm.selectedHelicopter.active),callback:function ($$v) {_vm.$set(_vm.selectedHelicopter, "active", $$v)},expression:"selectedHelicopter.active"}},[_vm._v(" "+_vm._s(_vm.$t('common.active'))+" ")]),_c('app-form-actions',{staticClass:"mt-5",attrs:{"form-name":"deposit-zone-form"},on:{"cancel":hide,"reset":function($event){_vm.selectedHelicopter = _vm._cloneDeep(_vm.initForm)}}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }